import React, { useEffect, useState, useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  fetchDocument,
  fetchForm,
  saveAnswers,
  updateAnswers,
} from '../apiServices';
import axios from 'axios';
import config from '../config';
import toast from 'react-hot-toast';
import { NotAuthorized } from './NotAuthorized';
import { SuccessForm } from './SuccessForm';
import { FormContent } from './Form';
import { formatQuestion } from '../utils/formatQuestion';
import getValidationSchema from './validationSchema';
import { formatDateToString } from './QuestionComponent';

interface FormValues {
  [key: string]: any;
}

interface Question {
  id: number;
  description: string;
  question_category: { description: string };
  question_access: { description: string };
  question_type?: { description: string };
  question_validation?: string;
  error_message?: string;
  is_required?: boolean;
  options?: any[];
  answers?: { question_id: number; answer_text: string }[];
}

const FormContainer: React.FC = () => {
  const [allAnswers, setAllAnswers] = useState<FormValues>({});
  const [validUser, setValidUser] = useState<boolean>(false);
  const [questionsByCategory, setQuestionsByCategory] = useState<
    Record<string, Question[]>
  >({});
  const [categories, setCategories] = useState<string[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [activeCategoryIndex, setActiveCategoryIndex] = useState<number>(0);
  const [nameForm, setNameForm] = useState<string>('');
  const [documentType, setDocumentType] = useState<string>('');
  const [previousDNI, setPreviousDNI] = useState<string>('');
  const [disabledFields, setDisabledFields] = useState<Record<string, boolean>>(
    {}
  );
  const [bankSelected, setBankSelected] = useState<string>('');
  const [showSaveSuccess, setShowSaveSuccess] = useState<boolean>(false);
  const [formUserId, setFormUserId] = useState<number | null>(null);
  const [hobbiesQuestionIds, setHobbiesQuestionIds] = useState<number[]>([]);
  const [questionIdMap, setQuestionIdMap] = useState<Record<string, number>>(
    {}
  );
  const [departments, setDepartments] = useState<any[]>([]);
  const [provinces, setProvinces] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);
  const [initialProvinces, setInitialProvinces] = useState<any[]>([]);
  const [initialDistricts, setInitialDistricts] = useState<any[]>([]);

  const [disabledSearchFields, setDisabledSearchFields] = useState<
    Record<string, boolean>
  >({});

  const activeCategory = categories[activeCategoryIndex] || '';
  const methods = useForm<FormValues>({
    resolver: yupResolver(
      getValidationSchema(
        questionsByCategory[activeCategory]?.map((q) =>{
          if (q.id === 9) {
            return { ...q, question_validation: null, is_required: 0 };
          } else if (q.id === 18) {
            return { ...q, question_validation: null, is_required: 0 };
          } else {
            return q;
          }
        }
        ),
        documentType,
        bankSelected
      )
    ),
    mode: 'onChange',
    shouldUnregister: false,
  });

  const {
    control,
    formState: { errors },
  } = methods;

  const errorDepartment = errors['question_departamento'];
  const errorProvince = errors['question_provincia'];
  const errorDistrict = errors['question_distrito'];
  const isInitialLoad = React.useRef(true);


  const documentTypeWatch = methods.watch(
    'question_tipo_de_documento'
  ) as string;
  const documentNumberWatch = methods.watch(
    'question_numero_de_documento'
  ) as string;
  const departmentWatch = methods.watch('question_departamento') as string;
  const provinceWatch = methods.watch('question_provincia') as string;

  const bankSelection = methods.watch('question_banco') as string;

  const organizeQuestionsByCategory = useCallback(
    (questions: Question[]) => {
      const categorizedQuestions: Record<string, Question[]> = {};
      const categoryOrder: string[] = [];
      const localQuestionIdMap: Record<string, number> = {};
      const localHobbiesQuestionIds: number[] = [];

      questions.forEach((question) => {
        if (
          question.question_access.description.toUpperCase() === 'TRABAJADOR'
        ) {
          const category = question.question_category.description;
          if (!categorizedQuestions[category]) {
            categorizedQuestions[category] = [];
            categoryOrder.push(category);
          }
          categorizedQuestions[category].push(question);
          const formattedDescription = formatQuestion(question.description);
          localQuestionIdMap[formattedDescription] = question.id;

          if (formattedDescription.includes('hobbies')) {
            localHobbiesQuestionIds.push(question.id);
          }
        }
      });

      setQuestionsByCategory(categorizedQuestions);
      setCategories(categoryOrder);
      setQuestionIdMap(localQuestionIdMap);
      setHobbiesQuestionIds(localHobbiesQuestionIds);

      setQuestionAnswers(
        categorizedQuestions[categoryOrder[activeCategoryIndex]]
      );
    },
    [activeCategoryIndex]
  );

  const loadData = useCallback(
    async (formId: number) => {
      try {
        const fetchedQuestions = await fetchForm(formId);
        console.log('Fetched questions:', fetchedQuestions);
  
        // Get the questions
        const departmentQuestion = fetchedQuestions?.form_type?.questions.find(
          (q: Question) => q.id === 33
        );
        const provinceQuestion = fetchedQuestions?.form_type?.questions.find(
          (q: Question) => q.id === 34
        );
        const districtQuestion = fetchedQuestions?.form_type?.questions.find(
          (q: Question) => q.id === 35
        );
  
        console.log('Department Question:', departmentQuestion);
        console.log('Province Question:', provinceQuestion);
        console.log('District Question:', districtQuestion);
  
        // Set the initial options
        setDepartments(departmentQuestion?.options || []);
        setInitialProvinces(provinceQuestion?.options || []);
        setInitialDistricts(districtQuestion?.options || []);
        console.log('Initial Provinces:', provinceQuestion?.answers);
        // Get the last answers
        const departmentAnswer = departmentQuestion?.answers?.[departmentQuestion.answers.length - 1];
        const provinceAnswer = provinceQuestion?.answers?.[provinceQuestion.answers.length - 1];
        const districtAnswer = districtQuestion?.answers?.[districtQuestion.answers.length - 1];
  
        console.log('Initial answers:', {
          department: departmentAnswer,
          province: provinceAnswer,
          district: districtAnswer
        });
  
        // Pre-filter provinces based on department answer
        if (departmentAnswer?.answer_text) {
          const filteredProvinces = (provinceQuestion?.options || []).filter(
            (p) => p.department_id === departmentAnswer.answer_text || 
                   p.department_id === parseInt(departmentAnswer.answer_text, 10)
          );
          setProvinces(filteredProvinces);
        }
  
        // Pre-filter districts based on province answer
        if (provinceAnswer?.answer_text) {
          const filteredDistricts = (districtQuestion?.options || []).filter(
            (d) => d.province_id === provinceAnswer.answer_text ||
                   d.province_id === parseInt(provinceAnswer.answer_text, 10)
          );
          setDistricts(filteredDistricts);
        }
  
        setNameForm(fetchedQuestions?.form_type?.description || '');
        const orderedQuestions =
          fetchedQuestions?.form_type?.questions.sort(
            (a: Question, b: Question) => a.id - b.id
          ) || [];
        
        organizeQuestionsByCategory(orderedQuestions);
      } catch (error) {
        console.error('Error in loadData:', error);
        toast.error('Ocurrió un error al obtener la información');
      }
    },
    [organizeQuestionsByCategory]
  );

  const setQuestionAnswers = (questions: Question[]) => {
    // First get all the ubigeo answers ready
    const departmentAnswer = questions.find(q => q.description.toLowerCase().includes('departamento'))?.answers?.[0]?.answer_text;
    const provinceAnswer = questions.find(q => q.description.toLowerCase().includes('provincia'))?.answers?.[0]?.answer_text;
    const districtAnswer = questions.find(q => q.description.toLowerCase().includes('distrito'))?.answers?.[0]?.answer_text;
  
    // Pre-filter provinces and districts
    if (departmentAnswer) {
      const filteredProvinces = initialProvinces.filter(
        (p) => p.department_id === departmentAnswer || 
               p.department_id === parseInt(departmentAnswer, 10)
      );
      setProvinces(filteredProvinces);
    }
  
    if (provinceAnswer) {
      const filteredDistricts = initialDistricts.filter(
        (d) => d.province_id === provinceAnswer || 
               d.province_id === parseInt(provinceAnswer, 10)
      );
      setDistricts(filteredDistricts);
    }
  
    // Now set all form values
    const data = questions.reduce((acc: FormValues, question: Question) => {
      const answer = question.answers?.[question.answers.length - 1];
      const fieldName = `question_${formatQuestion(question.description)}`;
      let answerValue = answer && answer.answer_text !== 'sin respuesta' ? answer.answer_text : '';
  
      if (question.question_type?.description.toLowerCase() === 'date' && answerValue) {
        const date = new Date(answerValue);
        if (!isNaN(date.getTime())) {
          answerValue = formatDateToString(date);
        }
      }
  
      acc[fieldName] = answerValue;
      return acc;
    }, {});

  
    // Set all values at once
    methods.reset(data);
  
    // Double check that values are set
    setTimeout(() => {
      const formValues = methods.getValues();
      console.log('Form values after set:', formValues);
    }, 0);
  };

  useEffect(() => {
    if (documentTypeWatch) {
      setDocumentType(documentTypeWatch);
    }
  }, [documentTypeWatch]);

  useEffect(() => {
    if (bankSelection) {
      setBankSelected(bankSelection);
    }
  }, [bankSelection]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        documentNumberWatch &&
        documentTypeWatch &&
        ((documentTypeWatch === '01' && documentNumberWatch.length === 8) ||
          (documentTypeWatch === '04' && documentNumberWatch.length === 9))
      ) {
        setPreviousDNI(documentNumberWatch);
        try {
          const data = await fetchDocument(
            documentNumberWatch,
            documentTypeWatch
          );
          if (!data) return;

          const [year, month, day] = data?.birthDate?.split('T')[0].split('-');

          const fieldsToUpdate = [
            'question_fecha_de_nacimiento',
            'question_apellido_paterno',
            'question_apellido_materno',
            'question_primer_nombre',
            'question_segundo_nombre',
            'question_tercer_nombre_o_mas',
          ];

          const newDisabledFields: Record<string, boolean> = {};

          for (const field of fieldsToUpdate) {
            newDisabledFields[field] = true;
          }

          await methods.setValue(
            'question_fecha_de_nacimiento',
            `${day}/${month}/${year}`,
            { shouldDirty: true }
          );
          await methods.setValue('question_apellido_paterno', data?.surname1);
          await methods.setValue('question_apellido_materno', data?.surname2);
          await methods.setValue('question_primer_nombre', data?.name1);
          await methods.setValue('question_segundo_nombre', data?.name2);
          await methods.setValue('question_tercer_nombre_o_mas', data?.name3);
          if (documentTypeWatch === '01') {
            await methods.setValue('question_nacionalidad', '9589');
            await methods.setValue('question_pais_emision_documento', '604');
          } else {
            await methods.resetField('question_nacionalidad');
            await methods.resetField('question_pais_emision_documento');
          }
          setDisabledSearchFields(newDisabledFields);
        } catch (error) {
          toast.error('Error al buscar documento');
        }
      } else if (
        (documentTypeWatch !== '01' && previousDNI) ||
        documentNumberWatch?.length !== 8
      ) {
        methods.resetField('question_pais_emision_documento');
        methods.resetField('question_fecha_de_nacimiento');
        methods.resetField('question_apellido_paterno');
        methods.resetField('question_apellido_materno');
        methods.resetField('question_primer_nombre');
        methods.resetField('question_segundo_nombre');
        methods.resetField('question_tercer_nombre_o_mas');
        methods.resetField('question_nacionalidad');
        methods.resetField('question_fecha_de_nacimiento');
        methods.resetField('question_pais_emision_documento');
        methods.resetField('question_nacionalidad');
        setDisabledSearchFields({});
      }
    };

    fetchData();
  }, [documentNumberWatch, previousDNI, documentTypeWatch, methods]);

  const validateToken = async (token: string) => {
    try {
      const response = await axios.post(
        `${config.REACT_APP_API_URL}/token-info`,
        { token }
      );
      setValidUser(response.data.status === 0);
      setFormUserId(response.data.form_user_id);
      loadData(response.data.form_user_id);
    } catch (error) {
      setValidUser(false);
    }
  };

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    const formUserId = new URLSearchParams(window.location.search).get(
      'id_form'
    );
    if (token) {
      if (formUserId) {
        setFormUserId(Number(formUserId));
        loadData(Number(formUserId));
        setValidUser(true);
        setIsAdmin(true);
        setToken(token);
      } else {
        validateToken(token);
      }
    } else {
      setValidUser(false);
    }
  }, [loadData]);

  useEffect(() => {
    if (questionsByCategory[activeCategory]) {
      setQuestionAnswers(questionsByCategory[activeCategory]);
    }
  }, [activeCategory, questionsByCategory]);

  const goToPreviousCategory = () => {
    setActiveCategoryIndex((prev) => prev - 1);
  };

  const onSubmit = async (data: FormValues) => {
    const updatedAnswers = {
      ...allAnswers,
      ...data,
    };
    setAllAnswers(updatedAnswers);

    if (activeCategoryIndex === categories.length - 1) {
      const formattedAnswers = Object.keys(updatedAnswers)
        .map((key) => {
          const descriptionKey = key.split('question_')[1];
          const descriptionFormatted = formatQuestion(descriptionKey);
          const questionId = questionIdMap[descriptionFormatted];

          let answerValue = updatedAnswers[key];

          if (descriptionKey.includes('fecha_de_nacimiento')) {
            //return format date to yyyy-mm-dd
            const [day, month, year] = answerValue.split('/');
            answerValue = `${year}-${month}-${day}`;
          }

          if (Array.isArray(answerValue)) {
            if (hobbiesQuestionIds.includes(questionId)) {
              answerValue = answerValue
                .map((item: any) => item.description || item)
                .join(', ');
            } else {
              answerValue = answerValue
                .map((item: any) => item.code || item)
                .join(', ');
            }
          }

          if (!answerValue) {
            answerValue = 'sin respuesta';
          }

          return questionId
            ? {
                form_user_id: formUserId,
                question_id: questionId,
                answer_text: answerValue,
              }
            : null;
        })
        .filter(Boolean);

      try {
        if (isAdmin) {
          await updateAnswers({ answers: formattedAnswers }, token);
        } else {
          await saveAnswers({ answers: formattedAnswers });
        }
        toast.success('Respuestas guardadas exitosamente');
        setShowSaveSuccess(true);
      } catch (error) {
        toast.error('Error al guardar respuestas');
      }
    } else {
      setActiveCategoryIndex((prevIndex) => prevIndex + 1);
    }
  };

  const hasChildrenValue = useWatch({
    control,
    name: 'question_hijos_(menores_de_18_anos)',
    defaultValue: '',
  });

  const sistemaPensionesValue = useWatch({
    control,
    name: 'question_elige_el_sistema_de_pensiones_al_que_aportas',
    defaultValue: '',
  });

  useEffect(() => {
    const newDisabledState = hasChildrenValue === '0' || hasChildrenValue === 2;
    setDisabledFields((prev) => ({
      ...prev,
      question__numero_de_hijos_menores_de_edad: newDisabledState,
    }));

    // Reset the dependent field when disabled
    if (newDisabledState) {
      methods.resetField('question__numero_de_hijos_menores_de_edad');
    }
  }, [hasChildrenValue, methods]);

  useEffect(() => {
    const getSelectedOption = () => {
      const currentCategory = questionsByCategory[activeCategory] || [];
      const pensionesQuestion = currentCategory.find((q) => q.id === 39);
      if (!pensionesQuestion || !sistemaPensionesValue) return null;

      const selectedOption = pensionesQuestion.options?.find(
        (opt) =>
          opt.id === sistemaPensionesValue ||
          opt.id === String(sistemaPensionesValue)
      );
      return selectedOption;
    };

    const selectedOption = getSelectedOption();

    // Check if the selected option description starts with "SPP"
    const isSPPOption = selectedOption?.description?.startsWith('SPP');

    const newDisabledState = !isSPPOption;
    setDisabledFields((prev) => ({
      ...prev,
      question_ingresa_tu_cuspp_de_afp: newDisabledState,
    }));

    // Reset CUSPP field when sistema de pensiones is not SPP
    if (newDisabledState) {
      methods.resetField('question_ingresa_tu_cuspp_de_afp');
    }
  }, [sistemaPensionesValue, questionsByCategory, activeCategory, methods]);

  useEffect(() => {
    if (departmentWatch) {
      const departmentCode = departmentWatch;
      const filteredProvinces = initialProvinces.filter(
        (p) =>
          p.department_id === departmentCode ||
          p.department_id === parseInt(departmentCode, 10)
      );
      setProvinces(filteredProvinces);
      
      if (!isInitialLoad.current) {
        methods.setValue('question_provincia', '');
        methods.setValue('question_distrito', '');
      }
    } else if (!isInitialLoad.current) { // Only clear if not initial load
      setProvinces([]);
      methods.resetField('question_provincia');
      methods.resetField('question_distrito');
    }
  }, [departmentWatch, initialProvinces, methods]);
  
  useEffect(() => {
    if (provinceWatch) {
      const provinceId = provinceWatch;
      const filteredDistricts = initialDistricts.filter(
        (d) =>
          d.province_id === provinceId ||
          d.province_id === parseInt(provinceId, 10)
      );
      setDistricts(filteredDistricts);
      
      if (!isInitialLoad.current) {
        methods.setValue('question_distrito', '');
      }
    } else if (!isInitialLoad.current) { // Only clear if not initial load
      setDistricts([]);
      methods.resetField('question_distrito');
    }
  }, [provinceWatch, initialDistricts, methods]);
  
  // Add a cleanup effect for initial load state
  useEffect(() => {
    return () => {
      isInitialLoad.current = true; // Reset on unmount
    };
  }, []);

  const renderFormContent = () => {
    return showSaveSuccess ? (
      <SuccessForm />
    ) : (
      <FormContent
        categories={categories}
        nameForm={nameForm}
        departments={departments}
        provinces={provinces}
        districts={districts}
        errorDepartment={errorDepartment}
        errorProvince={errorProvince}
        errorDistrict={errorDistrict}
        activeCategoryIndex={activeCategoryIndex}
        setActiveCategoryIndex={setActiveCategoryIndex}
        methods={methods}
        onSubmit={onSubmit}
        questionsByCategory={questionsByCategory}
        activeCategory={activeCategory}
        goToPreviousCategory={goToPreviousCategory}
        disabledFields={disabledFields}
        disabledSearchFields={disabledSearchFields}
      />
    );
  };

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      {validUser ? renderFormContent() : <NotAuthorized />}
    </div>
  );
};

export default FormContainer;
