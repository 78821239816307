import { Controller } from 'react-hook-form';
import Select from 'react-select';
export const UbigeoQuestion = ({
  question,
  methods,
  departments,
  provinces,
  errorDepartment, 
  errorProvince, 
  districts,
  errorDistrict
}) => {
  const { id, description, is_required } = question;
  // Determine which type of ubigeo question this is
  const isDepartamento = description.toLowerCase().includes('departamento');
  const isProvincia = description.toLowerCase().includes('provincia');
  const isDistrito = description.toLowerCase().includes('distrito');

  if (isDepartamento) {
    return (
      <>
        <label
          className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
          htmlFor="question_departamento"
        >
          {id}. {description}
          {is_required == 1 && (
            <span className="text-red-500 ml-1">*</span>
          )}
        </label>
        <Controller
          name="question_departamento"
          control={methods.control}
          defaultValue=""
          render={({ field }) => {
            // Find the matching department by code
            const selectedDepartment = departments.find(
              (option) => option.code == field.value || option.id == field.value
            );

            return (
              <Select
                {...field}
                value={selectedDepartment}
                onChange={(option) => {
                  field.onChange(option ? option.code : '');
                }}
                options={departments}
                isClearable
                isSearchable
                getOptionLabel={(option) => option.department}
                getOptionValue={(option) => option.code}
              />
            );
          }}
        />
        {is_required == 1 && errorDepartment && errorDepartment.message && (
          <p className="text-red-500 text-xs italic">
            {errorDepartment.message.toString()}
          </p>
        )}
      </>
    );
  }

  if (isProvincia) {
    return (
      <>
        <label
          className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
          htmlFor="question_provincia"
        >
          {id}. {description}
          {is_required == 1 && (
            <span className="text-red-500 ml-1">*</span>
          )}
        </label>
        <Controller
          name="question_provincia"
          control={methods.control}
          defaultValue=""
          render={({ field }) => {
            console.log('value', field.value, field);
            // Find the matching province by id or code
            const selectedProvince = provinces.find(
              (option) => option.id == field.value || option.code == field.value
            );

            return (
              <Select
                {...field}
                value={selectedProvince}
                onChange={(option) => {
                  field.onChange(option ? option.id : '');
                }}
                options={provinces}
                isClearable
                isSearchable
                getOptionLabel={(option) => option.province}
                getOptionValue={(option) => option.id}
                placeholder={provinces.length == 0 ? "Seleccione un departamento primero" : "Seleccione una provincia"}
                isDisabled={provinces.length == 0}
              />
            );
          }}
        />
        {is_required == 1 && errorProvince && errorProvince.message && (
          <p className="text-red-500 text-xs italic">
            {errorProvince.message.toString()}
          </p>
        )}
      </>
    );
  }

  if (isDistrito) {
    return (
      <>
        <label
          className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
          htmlFor="question_distrito"
        >
          {id}. {description}
          {is_required == 1 && (
            <span className="text-red-500 ml-1">*</span>
          )}
        </label>
        <Controller
          name="question_distrito"
          control={methods.control}
          defaultValue=""
          render={({ field }) => {
            // Find the matching district by id or code
            const selectedDistrict = districts.find(
              (option) => option.id == field.value || option.code == field.value
            );

            return (
              <Select
                {...field}
                value={selectedDistrict}
                onChange={(option) => {
                  field.onChange(option ? option.id : '');
                }}
                options={districts}
                isClearable
                isSearchable
                getOptionLabel={(option) => option.description}
                getOptionValue={(option) => option.id}
                placeholder={districts.length == 0 ? "Seleccione una provincia primero" : "Seleccione un distrito"}
                isDisabled={districts.length == 0}
              />
            );
          }}
        />
        {is_required == 1 && errorDistrict && errorDistrict.message && (
          <p className="text-red-500 text-xs italic">
            {errorDistrict.message.toString()}
          </p>
        )}
      </>
    );
  }

  return null;
};