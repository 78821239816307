import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useState } from 'react';

export const CategorySelector = ({
  categories,
  activeCategoryIndex,
  setActiveCategoryIndex,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative mb-4">
      <p className="block text-gray-700 text-sm font-semibold mb-2">
        Categorías del formulario
      </p>
      <button
        className="flex justify-between items-center w-full bg-white py-2 px-4 rounded-lg shadow cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="text-sm font-semibold text-gray-700">
          {categories[activeCategoryIndex]}
        </div>
        {isOpen ? (
          <ChevronUpIcon className="w-5 h-5" />
        ) : (
          <ChevronDownIcon className="w-5 h-5" />
        )}
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg flex flex-col">
          {categories.map((category, index) => (
            <button
              key={category}
              className={`text-xs font-semibold px-4 py-2 cursor-pointer text-left ${
                activeCategoryIndex === index
                  ? 'text-blue-500 bg-gray-100'
                  : 'text-gray-700 hover:bg-gray-100'
              }`}
              onClick={() => {
                setActiveCategoryIndex(index);
                setIsOpen(false);
              }}
            >
              {category}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
