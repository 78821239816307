import axios from 'axios';

// Base URL of your API
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`;

// Fetch question types
export const fetchQuestionTypes = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/question-type`);
    return response.data; // Assuming the data comes in the response's data field
  } catch (error) {
    console.error('Failed to fetch question types', error);
    return {};
  }
};

// Fetch statuses
export const fetchStatuses = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/status`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch statuses', error);
    return {};
  }
};

export const fetchForm = async (formId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/form-user/${formId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch questions', error);
    return [];
  }
};

export const fetchDocument = async (documentNumber, documentType) => {
  try {
    const token = 'I7gPhgZ/hkUU6uyYY4PLDMaKZ7cmxi2v8fDPUyjmjfI=';

    const response = await axios.get(
      `${process.env.REACT_APP_RENIEC_URL}/master-data?type=${documentType}&number=${documentNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data[0];
  } catch (error) {
    console.error('Failed to fetch document', error);
    return {};
  }
};


export const saveAnswers = async (answers) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/save-answers`, answers);
    return response.data;
  } catch (error) {
    console.error('Failed to save answers', error);
    return {};
  }
};

export const updateAnswers = async (answers, token) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/update-answers`, answers, {
      headers: {
        token: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update answers', error);
    return {};
  }
}
