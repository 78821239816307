import * as yup from 'yup';

const format = (text) =>
  text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/ /g, '_')
    .replace(/n°/g, 'n');

const getValidationSchema = (questions, documentType, bankSelected) => {
  const schemaFields = {};
  if (!questions || !questions.length) return yup.object(schemaFields);

  questions.forEach((question) => {
    const fieldName = `question_${format(question.description)}`;
    let rule;

    if (question.is_required) {
      rule = yup.string().required('Campo requerido');
    }



    switch (question.question_type?.description) {

      case 'documentNumber':
        rule = documentTypeSpecificRule(documentType);
        break;
      case 'cuenta bancaria':
        rule = bankNumberSpecificRule(bankSelected);
        break;
      default:
        // Include a default validation if needed
        break;
    }


    if (question.question_validation) {
      rule = yup.string().matches(new RegExp(question.question_validation), {
        message: question.error_message || 'Campo inválido',
        excludeEmptyString: !question.is_required
      });
    }

    if (rule) {
      schemaFields[fieldName] = rule;
    }
  });

  return yup.object(schemaFields);
};

function documentTypeSpecificRule(documentType) {
  switch (documentType) {
    case '01':
      return yup
        .string()
        .matches(/^\d{8}$/, 'DNI inválido')
        .required('DNI requerido');
    case '07':
      return yup
        .string()
        .matches(/^[A-Z]{2}\d{7}$/, 'Pasaporte inválido')
        .required('Pasaporte requerido');
    case '04':
      return yup
        .string()
        .matches(/^\d{12}$/, 'Carné inválido')
        .required('Carné requerido');
    case '06':
      return yup
        .string()
        .matches(/^\d{11}$/, 'RUC inválido')
        .required('RUC requerido');
    case '11':
      return yup
        .string()
        .matches(/^\d{6}$/, 'Partida inválida')
        .required('Partida requerida');
    default:
      return yup.string().required('Documento requerido');
  }
}

function bankNumberSpecificRule(bankSelected) {
  if (bankSelected == '02') {
    return yup
      .string()
      .matches(/^\d{14}$/, 'Número de cuenta inválido')
      .required('Número de cuenta requerido');
  } else {
    //number with more than 8 digits
    return yup
      .string()
      .matches(/^\d{8,}$/, 'Número de cuenta inválido')
      .required('Número de cuenta requerido');
  }
}

export default getValidationSchema;
