import React from 'react';
import Form from './components/FormContainer';
import './App.css'; // This is where you can import your Tailwind CSS if not globally imported

const App: React.FC = () => {
  return (
    <div className="App bg-primary min-h-screen">
      <main className="flex justify-center p-4">
        <Form />
      </main>
    </div>
  );
};

export default App;
