import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { formatQuestion } from '../utils/formatQuestion';
registerLocale('es', es);

interface Question {
  id: number;
  description: string;
  question_type: {
    description: string;
  };
  options?: any[];
  is_required: number;
  answers?: Array<{
    answer_text: string;
    question_id: number;
  }>;
}

interface QuestionProps {
  question: Question;
  disabled?: boolean;
  onChange?: (value: any) => void;
  options?: any[];
}

const Label = ({ htmlFor, children, tooltip, isRequired }) => (
  <label
    htmlFor={htmlFor}
    className="block text-gray-700 text-sm font-semibold mb-2"
  >
    {children}
    {isRequired && <span className="text-red-500 ml-1">*</span>}
    {tooltip && (
      <span className="text-xs text-gray-500 ml-1">
        - Verificar en{' '}
        <a
          href="https://servicios.sbs.gob.pe/ReporteSituacionPrevisional/Afil_Consulta.aspx"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 underline"
        >
          este link
        </a>
      </span>
    )}
  </label>
);

export const formatDateToString = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const parseDateFromString = (value: string): Date | null => {
  if (!value) return null;
  if (value.includes('-')) {
    const [year, month, day] = value.split('-').map(Number);
    return new Date(year, month - 1, day);
  } else if (value.includes('/')) {
    const [day, month, year] = value.split('/').map(Number);
    return new Date(year, month - 1, day);
  }
  return null;
};

const QuestionComponent: React.FC<QuestionProps> = ({
  question,
  disabled,
  onChange,
  options
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const fieldName = `question_${formatQuestion(question.description)}`;
  const error = errors[fieldName] as any;
  const isPensionSystem = question.description
    .toLowerCase()
    .includes('sistema de pensiones');

  const getInitialSelectValue = (field: any) => {
    const questionOptions = options || question.options;
    if (!questionOptions) return null;

    // For multi-select (hobbies)
    if (question.description.toLowerCase().includes('hobbies')) {
      if (Array.isArray(field.value)) {
        return field.value.map(val => 
          questionOptions.find(opt => opt.id === val || opt.id === String(val))
        ).filter(Boolean);
      }
      return [];
    }

    // For single select
    const value = field.value;
    if (!value) return null;
    // Try to find the option that matches the value
    const option = questionOptions.find(opt => 
      opt.code === value || opt.id === value || opt.id === String(value)
    );
    return option || null;
  };

  return (
    <div className="mb-6">
      <Label
        htmlFor={`question_${question.id}`}
        isRequired={question.is_required === 1}
        tooltip={isPensionSystem ? 'Verificar en este link' : undefined}
      >
        {question.id}. {question.description}
      </Label>

      <Controller
        name={fieldName}
        control={control}
        defaultValue=""
        render={({ field }) => {
          switch (question.question_type.description.toLowerCase()) {

            case 'select': {
              const isMulti = question.description.toLowerCase().includes('hobbies');
              return (
                <>
                  <Select
                    {...field}
                    options={options || question.options}
                    value={getInitialSelectValue(field)}
                    onChange={(selectedOption) => {
                      if (isMulti) {
                        const values = selectedOption ? selectedOption.map(opt => opt.code) : [];
                        field.onChange(values);
                        if (onChange) onChange(selectedOption);
                      } else {
                        field.onChange(selectedOption ? selectedOption.code : '');
                        if (onChange) onChange(selectedOption);
                      }
                    }}
                    className="text-base w-full mt-1 cursor-pointer"
                    isClearable
                    isMulti={isMulti}
                    placeholder="Seleccione una opción"
                    noOptionsMessage={() => 'No hay opciones disponibles'}
                    getOptionLabel={(option) => 
                      option.description || option.department || option.province || ''
                    }
                    getOptionValue={(option) => option.code}
                    isDisabled={disabled}
                  />
                  {error && (
                    <p className="text-red-500 text-xs italic" id={`error-${question.id}`}>
                      {error.message}
                    </p>
                  )}
                </>
              );
            }
            
            case 'date':
              return (
                <>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={field.value ? parseDateFromString(field.value) : null}
                    onChange={(date) => field.onChange(date ? formatDateToString(date) : '')}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    locale="es"
                    placeholderText="dd/mm/aaaa"
                    disabled={disabled}
                  />
                  {error && (
                    <p className="text-red-500 text-xs italic" id={`error-${question.id}`}>
                      {error.message}
                    </p>
                  )}
                </>
              );

            case 'number':
              return (
                <>
                  <input
                    {...field}
                    type="number"
                    disabled={disabled}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    min="0"
                    onKeyPress={(e) => {
                      if (e.key === '-' || e.key === '+') e.preventDefault();
                    }}
                  />
                  {error && (
                    <p className="text-red-500 text-xs italic" id={`error-${question.id}`}>
                      {error.message}
                    </p>
                  )}
                </>
              );

            default:
              return (
                <>
                  <input
                    {...field}
                    type="text"
                    disabled={disabled}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                  {error && (
                    <p className="text-red-500 text-xs italic" id={`error-${question.id}`}>
                      {error.message}
                    </p>
                  )}
                </>
              );
          }
        }}
      />
    </div>
  );
};

export default React.memo(QuestionComponent);