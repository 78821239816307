export const SuccessForm = () => {
  return (
    <div className="flex flex-col w-full pt-4">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg w-full">
        <h3 className="text-xl font-semibold text-left">
          ¡Gracias por completar el formulario!
        </h3>
        <p className="text-left mt-4">Hemos recibido tus respuestas.</p>
        {/* añadir botón de cerrar pestana */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => {
              window.opener = null;
              window.open('', '_self');
              window.close();
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};
