import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { formatQuestion } from '../utils/formatQuestion';

export const HobbyQuestion = ({ question }) => {
  const { control } = useFormContext();
  return (
    <>
      <label
        className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
        htmlFor={`question_${formatQuestion(question.description)}`}
      >
        {question.order}. {question.description}
      </label>
      <Controller
        name={`question_${formatQuestion(question.description)}`}
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <Select
            {...field}
            options={question.options}
            onChange={(value) => 
              field.onChange(value ? value.map((option) => option.code) : [])
            }
            isClearable
            isSearchable
            placeholder="Seleccione una opción"
            className="text-base w-full mt-1 cursor-pointer"
            getOptionValue={(option) => option.code}
            getOptionLabel={(option) => option.description}
            isMulti
            value={question.options.filter((option) =>
              field.value.includes(option.code)
            )}
          />
        )}
      />
    </>
  );
};