export const NotAuthorized = () => {
  return (
    <div className="flex flex-col w-full pt-4">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg w-full">
        <h3 className="text-xl font-semibold text-left">
          Sin autorización, por favor contacte al administrador.
        </h3>
      </div>
    </div>
  );
};
